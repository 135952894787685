import { Client, Account, OAuthProvider} from 'appwrite';

export const client = new Client();

client
    .setEndpoint('https://dev.folo.shop/v1')
    .setProject('6552291c54fa3fe9cfb2');

export const account = new Account(client);

export { ID } from 'appwrite';
export { OAuthProvider }
