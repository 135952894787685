import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {encode as base64_encode} from 'base-64';
import './lib/App.css';
import OrderCard from './components/OrderCard';
import { Container, NavLink, Nav, Row, Col, Badge } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const OrderList = () => {
  const { state } = useLocation();
  const [serverError, setError] = useState('');
  const [orders, setOrders] = useState(null);
  const [interval, setInterval] = useState('day');

  useEffect(() => {
    const init = async () => {
      const request = new Request(state.url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + base64_encode("dev@folo.ovh:3NjFvGqMKCbgIMLoDsxn"),
        },
        body: JSON.stringify({
          "user_id": state.user.$id, //(await user).$id
          "interval": interval,
          "limit": state.limit,
        }),
      });
      setOrders(null);
      fetch(request)
      .then(response => response.json())
      .then(json => setOrders(json))
      .catch(error => setError(error));
    }

    init()
  }, [state.url, state.user.$id, interval, state.limit])

  function getTotalNotifications() {
    let _total = 0;
    if (orders) {
      const data_str = JSON.stringify(orders);
      const data_json = JSON.parse(data_str);
      data_json.forEach((d) => {
        _total++;
      });
    }
    return _total;
  };

  return(
    <Container fluid="sm">
      <div className='App-header'>
        <Row style={{backgroundColor:"transparent"}}>
          <Col sm={4} style={{backgroundColor:"transparent"}}>FOLO.shop</Col>
          <Col sm={4} style={{backgroundColor:"transparent"}}>{orders ? <p style={{"textAlign":"center"}}>{state.title}<span>&nbsp;:&nbsp;</span>{getTotalNotifications()}</p> : ""}</Col>
          <Col sm={4} style={{backgroundColor:"transparent"}}><NavLink href='/' style={{"textAlign":"right", "color":"skyblue"}}>Home</NavLink></Col>
        </Row>
      </div>
      <div>
        <Nav variant="pills" style={{"padding":"0px", "align-items":"center", "justify-content":"center"}}
          activeKey={interval}
          onSelect={(eventKey) => {setInterval(eventKey);}}
        >
          {state.totals.map((item) => (
            (`${item['list']}` === 'orders') ? 
              <Nav.Item>
                <Nav.Link eventKey={`${item['interval']}`}>{`${item['title']}`}
                  &nbsp;&nbsp;<Badge pill bg="secondary">&nbsp;&nbsp;{item['total']}&nbsp;&nbsp;</Badge></Nav.Link>
              </Nav.Item>
              :
              null
            ))
          }
        </Nav>
      </div>
      <div>
          {orders ?
            JSON.parse(JSON.stringify(orders)).map((order) => (
              <pre>
                <OrderCard orderRow={order} />
              </pre>
            ))
            : <div style={{textAlign:"center"}}><Spinner animation="grow" variant="primary" /></div>
          }
      </div>
    </Container>
  );  
};

export default OrderList;