// src/Auth.js
import React, { useState } from 'react';
import { account, ID, OAuthProvider } from '../lib/appwrite';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loginError, setError] = useState('');

  async function getLogin(method) {
    if (method === 'google') {
      try {
        account.createOAuth2Session(OAuthProvider.Google, 'https://web.folo.shop', 'https://web.folo.shop');
      } catch (err) {
        setError(err.type);
      }
    }
    else {
      try {
        await account.createEmailPasswordSession(email, password);
      } catch (err) {
        setError(err.type);
      }
    }
  }

  return (
      <div>
        <img className='login-image' src={require("../images/google_login.png")} alt='Login with Google' onClick={ () => {
          getLogin('google')}}>
        </img>
        <br/>
        <form id='myForm'>
            <label for="email">Email</label>{loginError ? (<span style={{"color": "red"}}> {loginError}</span>) : ""}
            <input type="email" id="email" placeholder="Your email .." value={email} onChange={e => setEmail(e.target.value)} autoFocus />
            <label for="password">Password</label>
            <input type="password" id="password" placeholder="Your password .." value={password} onChange={e => setPassword(e.target.value)} />
            <label for="fname">Full Name</label>
            <input type="text" id="fname" placeholder="Your name .." value={name} onChange={e => setName(e.target.value)} />
            
            <button type='submit'
                onClick={ () => {
                  getLogin(null);
                }}>Log In
            </button>
              
            <button type='submit'
                onClick={async () => {
                    await account.create(ID.unique(), email, password, name);
                    getLogin();
                }}>Sign Up
            </button>
        </form> 
      </div>
  )
};

export default Login;