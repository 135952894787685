import React, { useState } from 'react';
import {encode as base64_encode} from 'base-64';
import '../lib/App.css';
import formatDate from '../lib/DateFormatter';
import { serverUrl } from '../lib/Tracking_Backend';
import { Button, Card, CardBody, CardFooter, CardHeader, CardText } from 'react-bootstrap';

function EmailCard({emailRow}) {
    const [email, setEmail] = useState(emailRow);
    const [htmlContent, setHtmlContent] = useState(null);
    const items = email.items !== "" ? email.items.split('|') : NaN
    
    // useEffect(() => {
    //     const updateContent = () => {
    //         setHtmlContent("<p>Updated content based on user interaction</p>");
    //     };
    //     }, []
    // )

    const onDeleteClick = () => {
        if (window.confirm("Please Confirm! About to permenantly DELETE email from: " + email.sender_name + ".") === true) {
          var url = serverUrl + '/delete-email/'+ email.email_id;
          var request = new Request(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Basic " + base64_encode("dev@folo.ovh:3NjFvGqMKCbgIMLoDsxn"),
            },
          });
          fetch(request)
            .then(response => response.json())
            .then(json => setEmail(json))
            .catch(error => alert(error));
        } 
    };

    return(
        <>
            <Card bg='dark' style={{"padding": "0px"}}>
                <CardHeader>
                    <img className="logo" src='./favicon.ico' alt='brand name' />
                    <h2 style={{"color":"whitesmoke"}}>{email.sender_name}</h2>
                </CardHeader>
                <CardBody  class='text-wrap'>
                    <CardText style={{"fontSize":"medium"}}>
                        <strong>Tracking Issue: </strong><span style={{color:"orangered"}}>{email.tracking_problems}</span>
                        <br/>Email Received: {formatDate(email.email_date)}
                        <br/>Email Subject: {email.email_subject}
                        <br/>Tracking Number: {email.tracking_no}
                        <br/><strong>Items: </strong>{items ? <ol>{items.map((item, index) => {return <li>{item}</li>})}</ol> : "Not Given .."}
                    </CardText>
                </CardBody>
                <CardFooter class='text-wrap'>
                    <Button variant="link" onClick={() => {onDeleteClick();}}>Delete Email</Button>
                    <br/>
                    {
                        htmlContent 
                        ? <Button variant="link" onClick={() => {setHtmlContent(null);}}>Hide Original Email</Button>
                        : <Button variant="link" onClick={() => {setHtmlContent(email.email_html);}}>See Original Email</Button>
                    }
                </CardFooter>
            </Card>
            { htmlContent ? <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> : ""}
        </>
    )
}
export default EmailCard;