import formatDate from "../lib/DateFormatter";

function Tracking({transaction}) {
    return(
        <>
            <tr>
                <strong>{formatDate(transaction.email_date)}</strong>
                <br/>{transaction.email_subject}
                <br/>Sent By: {transaction.sender_name}
                <br/>Expected Delivery Date: {transaction.expected_delivery_date}
                <br/>Order No.: {transaction.order_no} | Parcel No.: {transaction.tracking_no}
            </tr>
        </>
    )
}
export default Tracking;