import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {encode as base64_encode} from 'base-64';
import './lib/App.css';
import ArchivedOrderCard from './components/ArchivedOrderCard';
import { Container, NavLink, Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const ArchivesList = () => {
  const { state } = useLocation();
  const [serverError, setError] = useState('');
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    const init = async () => {
      const request = new Request(state.url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + base64_encode("dev@folo.ovh:3NjFvGqMKCbgIMLoDsxn"),
        },
      });
      setOrders(null);
      fetch(request)
      .then(response => response.json())
      .then(json => setOrders(json))
      .catch(error => setError(error));
    }

    init()
  }, [state.url, state.user.$id, state.limit])

  function getTotalNotifications() {
    let _total = 0;
    if (orders) {
      const data_str = JSON.stringify(orders);
      const data_json = JSON.parse(data_str);
      data_json.forEach((d) => {
        _total++;
      });
    }
    return _total;
  };

  return(
    <Container fluid="sm">
      <div className='App-header'>
        <Row style={{backgroundColor:"transparent"}}>
          <Col sm={4} style={{backgroundColor:"transparent"}}>FOLO.shop</Col>
          <Col sm={4} style={{backgroundColor:"transparent"}}>{orders ? <p style={{"textAlign":"center"}}>{state.title}<span>&nbsp;:&nbsp;</span>{getTotalNotifications()}</p> : ""}</Col>
          <Col sm={4} style={{backgroundColor:"transparent"}}><NavLink href='/' style={{"textAlign":"right", "color":"skyblue"}}>Home</NavLink></Col>
        </Row>
      </div>
      <div>
          {orders ?
            JSON.parse(JSON.stringify(orders)).map((order) => (
              <pre>
                <ArchivedOrderCard orderRow={order} />
              </pre>
            ))
            : <div style={{textAlign:"center"}}><Spinner animation="grow" variant="primary" /></div>
          }
      </div>
    </Container>
  );  
};

export default ArchivesList;