import React, { useState } from 'react';
import { Container, Badge } from 'react-bootstrap';

const Dashboard = ({totalsDict}) => {
    const [totals, setTotals] = useState(totalsDict);

    return(
      <Container fluid="sm" style={{padding:"0px"}}>
        <div style={{padding:"0px"}}>
          {JSON.parse(JSON.stringify(totals)).map((item) => (
              <p>{item['title']}&nbsp;&nbsp;<Badge pill bg={item['color']}>&nbsp;&nbsp;{item['total']}&nbsp;&nbsp;</Badge><br/></p>
            ))
          }
        </div>
      </Container>
    )
}
export default Dashboard;