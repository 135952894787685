import React, { useState, useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { account } from './lib/appwrite';
import { Col, Row, Button, Nav, Badge } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import { serverUrl } from './lib/Tracking_Backend';
import {encode as base64_encode} from 'base-64';

const Home = () => {
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userId, setUserId] = useState(null);
    const [queryObj, setQueryObj] = useState(null);
    const [totals, setTotals] = useState(null);
    const [serverError, setError] = useState('');

    useEffect(() => {
        const checkUser = async () => {
          try {
            const userData = await account.get();
            setLoggedInUser(userData);
            setUserId(userData.$id);
          } catch (err) {
            setLoggedInUser(null);
          }
        }
    
        checkUser()
      }, [])

    useEffect(() => {
      if (userId) {
        const url = serverUrl + "/dashboard?userid=" + userId
        const request = new Request(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + base64_encode("dev@folo.ovh:3NjFvGqMKCbgIMLoDsxn"),
          },
        });
        fetch(request)
        .then(response => response.json())
        .then(json => setTotals(json))
        .catch(error => setError(error));
      }
    }, [userId])

    async function onTrackingClick(page){
      let query = {};
      if (page === "orders") {
        query = {
          user: loggedInUser,
          url: serverUrl + "/orders-list",
          limit: 500,
          totals: totals,
          page: page,
          title: 'Total Updates',
        };
      } else if (page === "failed-tracking") {
          query = {
            user: loggedInUser,
            url: serverUrl + "/failed-tracking-list",
            limit: 500,
            totals: null,
            page: page,
            title: 'Total Unsloved',
          };
      } else if (page === "archives") {
        query = {
          user: loggedInUser,
          url: serverUrl + "/archives-list/" + loggedInUser.$id,
          limit: 500,
          totals: null,
          page: page,
          title: 'Total Archived',
        };
      } else {
        query = {
          user: loggedInUser,
          url: serverUrl + "/emails-list",
          limit: 500,
          totals: null,
          page: page,
          title: 'Total Unsloved',
        };
      }
      setQueryObj(query);
    }

    async function onHandleLogoutClick() {
      await account.deleteSession('current');
      setLoggedInUser(null);
    };

    if (queryObj) {
      let navTo = "/" + queryObj.page;
      return <Navigate to={navTo} state={queryObj} />
    }
    else {
      return(
          <div className='App'>
              <header className="login-header">
                  Welcome to FOLO.shop
                  <p>Your Personal Shopping Assistant</p>
              </header>
              <Row>
                  <Col sm="6">
                    {loggedInUser ? 
                      <div style={{padding:"0px"}}>
                        <h2><strong>Bonjour {loggedInUser.name}</strong></h2><br/>
                        {totals ?
                          <>
                            <Dashboard totalsDict={totals} />
                            <Nav className="flex-column" style={{paddingLeft:"0px"}}>
                                <Nav.Link href='' onClick={() => {onTrackingClick("orders");}} style={{paddingLeft:"0px", paddingBottom:"0px"}}>Proceed to Tracking</Nav.Link>
                                <Nav.Link href='' onClick={() => {onTrackingClick("failed-tracking");}} style={{paddingLeft:"0px", paddingBottom:"0px"}}>Failed to Track!!</Nav.Link>
                                <Nav.Link href='' onClick={() => {onTrackingClick("emails");}} style={{paddingLeft:"0px", paddingBottom:"0px"}}>Failed to Parse!!</Nav.Link>
                                <Nav.Link href='' onClick={() => {onTrackingClick("archives");}} style={{paddingLeft:"0px", paddingBottom:"0px"}}>Archives</Nav.Link>
                            </Nav>
                          </>
                          :
                          <Spinner animation="grow" variant="primary" />
                        }              
                      </div>
                      : <p><strong>Sign In to get the Dashboard!!</strong></p>
                    }
                  </Col>
                  <Col sm="6" style={{padding:"0px"}}>
                    {loggedInUser ? 
                      <div style={{padding:"0px"}}>
                        <Button variant='link'>Profile</Button><br/>
                        <Button variant='link'>Change Password</Button><br/>
                        <Button variant='link'>Reset Password</Button><br/>
                        <Button variant='link' onClick={() => {onHandleLogoutClick();}}>Sign Out</Button>
                      </div>
                      : <div style={{padding:"0px"}}>
                        New to FOLO.shop? <a href="">Sign Up for a new Account</a>
                        Already have an Account? <Login />
                      </div>
                    }
                    <div style={{padding:"0px"}}>
                      <Button variant='link'>About</Button><br/>
                      <Button variant='link'>Guide</Button><br/>
                      <Button variant='link'>News</Button>
                    </div>
                  </Col>
              </Row>
          </div>
      )
  }
}
export default Home;