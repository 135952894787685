import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import "./lib/App.css";
import OrderList from "./OrderList";
import EmailList from "./EmailList";
import FailedTrackingList from "./FailedTrackingList";
import ArchivesList from "./ArchivesList";
import ErrorPage from "./error-page";
import Home from "./Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/orders",
    element: <OrderList />,
  },
  {
    path: "/failed-tracking",
    element: <FailedTrackingList />,
  },
  {
    path: "/emails",
    element: <EmailList />,
  },
  {
    path: "/archives",
    element: <ArchivesList />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);