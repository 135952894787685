import React, { useState } from 'react';
import '../lib/App.css';
import { Card, CardBody, CardFooter, CardHeader, CardText, Row, Col } from 'react-bootstrap';
import formatDate from '../lib/DateFormatter';

function FailedTrackingCard({orderRow}) {
    const [order, setOrder] = useState(orderRow);

    return(
        <>
        <Card bg='dark' style={{"padding": "0px"}}>
            <CardHeader>
                <Row style={{backgroundColor:"transparent", padding:"0px"}}>
                    <Col sm={6} style={{backgroundColor:"transparent", "color":"whitesmoke"}}>
                        <img className="logo" src='./favicon.ico' alt='brand name' />
                        <h3>{order.notification_sender}</h3>
                    </Col>
                    <Col sm={6} style={{backgroundColor:"transparent", "color":"whitesmoke"}}>
                        <span>Last Updated: {formatDate(order.notification_date)}</span>
                        <br/><span style={{textWrap:"wrap"}}><strong>{order.notification_subject}</strong></span>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody class='text-wrap'>
                <CardText style={{"fontSize":"medium"}}>
                    <p><strong>Order No: </strong>{order.order_no}</p>
                    <p><strong>Order Date: </strong>{order.order_date}</p>
                    <p><strong>Parcel: </strong>{order.tracking_no}</p>
                    <p><strong>Expedited By: </strong>{order.expedited_by}</p>
                    <p><strong>Carrier: </strong>{order.carrier_name}</p>
                    <p class='text-wrap'>Delivery Location: {order.delivery_location}</p>
                    <p><strong>Expected Delivery On: </strong>{order.expected_delivery_date}</p>
                    <p>Delivery Code: {order.delivery_code}</p>
                </CardText>
            </CardBody>
            <CardFooter class='text-wrap'>
                
            </CardFooter>
        </Card>
        </>
    )
};

export default FailedTrackingCard;