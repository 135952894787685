import React, { useEffect, useState } from 'react';
import '../lib/App.css';
import { Card, CardBody, CardFooter, CardHeader, CardText, Row, Col } from 'react-bootstrap';
import formatDate from '../lib/DateFormatter';

function ArchivedOrderCard({orderRow}) {
    const [order, setOrder] = useState(orderRow);
    const [emojiCode, setEmojiCode] = useState(null);
    const items = order.items !== "" ? order.items.split('|') : NaN
    const card_bg = order.is_actionable === true ? 'info' : 'dark'
    
    useEffect(() => {
        const init = async () => {
            let emojis = {
                happy: '128578',
                unhappy: '128556',
                angy: '129324',
                pass: '128566'
            };
            setEmojiCode(emojis[order.shopper_rating]);
        };
        init()
    },[order.shopper_rating])

    return(
        <>
        <Card bg={card_bg} style={{"padding": "0px"}}>
            <CardHeader>
                <Row style={{backgroundColor:"transparent", padding:"0px"}}>
                    <Col sm={4} style={{backgroundColor:"transparent", "color":"whitesmoke"}}>
                        <img className="logo" src='./favicon.ico' alt='brand name' />
                        <h3>{order.brand_name}</h3>
                    </Col>
                    <Col sm={4} style={{backgroundColor:"transparent", textAlign:"center"}}>
                        {emojiCode ? 
                            <span style={{fontSize:'30px', paddingRight:"5px"}}>{String.fromCodePoint('128578')}</span>
                            : ''
                        }
                    </Col>
                    <Col sm={4} style={{backgroundColor:"transparent", "color":"whitesmoke"}}>
                        <span>Archived on: {formatDate(order.closed_date)}</span>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody class='text-wrap'>
                <CardText style={{"fontSize":"medium"}}>
                    <p><strong>Order No: </strong>{order.order_no}</p>
                    <p><strong>Order Date: </strong>{order.order_date}</p>
                    <p><strong>Order Total Amount: </strong>{order.order_total_amount}</p>
                    <p><strong>Items:</strong></p>
                    {items ? <ol>{items.map((item, index) => {return <li>{item}</li>})}</ol> : "Not Given .."}
                </CardText>
            </CardBody>
            <CardFooter class='text-wrap'>
                <p style={{backgroundColor:"gray", color:"whitesmoke"}}><strong>Shopper Feedback: {order.shopper_feedback}</strong></p>
            </CardFooter>
        </Card>
        </>
    )
};

export default ArchivedOrderCard;