import React, { useState } from 'react';
import {encode as base64_encode} from 'base-64';
import '../lib/App.css';
import { Accordion, AccordionBody, Button, Card, CardBody, CardFooter, CardHeader, CardText, Nav, Row, Col, Form, InputGroup } from 'react-bootstrap';
import Tracking from './Tracking';
import formatDate from '../lib/DateFormatter';
import { serverUrl } from '../lib/Tracking_Backend';

function OrderCard({orderRow}) {
    const [order, setOrder] = useState(orderRow);
    const [serverError, setError] = useState('');
    const [issue, setIssue] = useState(null);
    const items = order.items !== "" ? order.items.split('|') : NaN
    const [showFeedback, setShowFeedback] = useState(false);
    const [showDeliveredEmojis, setShowDeliveredEmojis] = useState(false);

    const handleFeedbackShow = () => setShowFeedback(true);
    const handleFeedbackClose = () => setShowFeedback(false);
    const handleDeliveredEmojisShow = () => setShowDeliveredEmojis(true);
    const handleDeliveredEmojisClose = () => setShowDeliveredEmojis(false);

    const onSubmitReportProblemFormClick = () => {
        var url = serverUrl + '/user-feedback';
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic " + base64_encode("dev@folo.ovh:3NjFvGqMKCbgIMLoDsxn"));
    
        const raw = JSON.stringify({
          "order_id": order.order_id,
          "feedback": issue
        });
    
        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw
        };
    
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(json => setOrder(json))
            .catch((error) => alert(error));
        handleFeedbackClose();
        alert("Feedback Submitted.");
    }

    function onEmojisClick(emotion) {
        if (window.confirm("Please Confirm! About to permenantly CLOSE and Archive Order:" + order.order_no + ".") === true) {
            var url = serverUrl + '/archive-order';
            var request = new Request(url, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + base64_encode("dev@folo.ovh:3NjFvGqMKCbgIMLoDsxn"),
              },
              body: JSON.stringify({
                "order_id": order.order_id,
                "emotion": emotion
              }),
            });
            fetch(request)
              .then(response => response.json())
              .then(json => setOrder(json))
              .then(handleDeliveredEmojisClose(false))
              .catch(error => alert(error));
        }
    }
  
    return(
        <>
        <Card bg='dark' style={{"padding": "0px"}}>
            <CardHeader>
                <Row style={{backgroundColor:"transparent", padding:"0px"}}>
                    <Col sm={6} style={{backgroundColor:"transparent", "color":"whitesmoke"}}>
                        <img className="logo" src='./favicon.ico' alt='brand name' />
                        <h3>{order.brand_name ? order.brand_name : order.update_sender}</h3>
                    </Col>
                    <Col sm={6} style={{backgroundColor:"transparent", "color":"whitesmoke"}}>
                        <span>Last Updated: {formatDate(order.updated_date)}</span>
                        <br/><span style={{textWrap:"wrap"}}><strong>{order.last_update}</strong></span>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody class='text-wrap'>
                <CardText style={{"fontSize":"medium"}}>
                    <Accordion style={{"padding": "0px", "paddingTop":"10px"}} flush>
                        <Accordion.Item style={{"padding": "0px"}} eventKey='0'>
                            <Accordion.Header>Order: {order.order_no}</Accordion.Header>
                            <AccordionBody>
                                <p>Order Date: {order.order_date}</p>
                                <p><strong>Items:</strong></p>
                                {items ? <ol>{items.map((item, index) => {return <li>{item}</li>})}</ol> : "Not Given .."}
                            </AccordionBody>
                        </Accordion.Item>
                        <Accordion.Item style={{"padding": "0px"}} eventKey='1'>
                            <Accordion.Header>Expected Delivery: {order.expected_delivery_date ? order.expected_delivery_date : "TBD"}</Accordion.Header>
                            <AccordionBody>
                                <strong>Parcel No: </strong>{order.tracking_no}
                                <br/><strong>Expedited By: </strong>{order.expedited_by}
                                <br/><strong>Carrier: </strong>{order.carrier_name}
                                <br/><strong class='text-wrap'>Delivery Location: </strong>{order.delivery_location}
                                <br/>Delivery Code: {order.delivery_code}
                            </AccordionBody>
                        </Accordion.Item>
                        <Accordion.Item style={{"padding": "0px"}} eventKey='2'>
                            <Accordion.Header>Timeline</Accordion.Header>
                            <AccordionBody>
                                {JSON.parse(JSON.stringify(order.orderDetails)).map((trn) => (
                                        <Tracking transaction={trn} />    
                                    ))
                                }           
                            </AccordionBody>
                        </Accordion.Item>
                    </Accordion>
                </CardText>
            </CardBody>
            <CardFooter class='text-wrap'>
                {!order.is_closed ?
                    showDeliveredEmojis ? 
                        <Button
                            variant="link"
                            onClick={() => {handleDeliveredEmojisClose()}}>Cancel
                        </Button>
                        :
                        <Button
                            variant="link"
                            onClick={() => {handleDeliveredEmojisShow()}}>Delivered?
                        </Button>
                    : null
                }
                {showDeliveredEmojis ?
                    <Nav pills style={{"padding":"0px", "align-items":"center", "justify-content":"center"}}
                        onSelect={(eventKey) => {onEmojisClick(eventKey);}}
                    >
                        <Nav.Link eventKey="happy">
                        <span style={{fontSize:'30px', paddingRight:"0px"}}>&#128578;</span>
                        </Nav.Link>
                        <Nav.Link eventKey="unhappy">
                        <span style={{fontSize:'30px', paddingRight:"5px"}}>&#128556;</span>
                        </Nav.Link>
                        <Nav.Link eventKey="angry">
                        <span style={{fontSize:'30px', paddingRight:"5px"}}>&#129324;</span>
                        </Nav.Link>
                        <Nav.Link eventKey="Pass">
                        <span style={{fontSize:'30px', paddingRight:"5px"}}>&#128566;</span>
                        </Nav.Link>
                    </Nav>
                    : null
                }
                {order.user_feedback ?
                    <p>
                        <br/>{'> '}<strong>Reported Problem: </strong>{order.tester_feedback}
                        <br/>{'> '}<strong>Admin Response: </strong>{order.tester_reply}
                    </p>
                    :
                    !order.is_closed ?
                        <Button
                            variant="link"
                            onClick={() => {handleFeedbackShow()}}>Problem?
                        </Button>
                    : null
                }
                {showFeedback ?
                    <Form>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Describe the tracking problem and anything else which may help analysis"
                                aria-label="Issue Description"
                                aria-describedby="issue"
                                value={issue} 
                                onChange={e => setIssue(e.target.value)} autoFocus
                            />
                            <Button variant="outline-secondary" id="button-addon1"
                                onClick={ () => {
                                    handleFeedbackClose();
                                }}>
                                Cancel
                            </Button>
                            <Button variant="outline-primary" id="button-addon2"
                                onClick={ () => {
                                    onSubmitReportProblemFormClick();
                                }}>
                                Submit
                            </Button>
                        </InputGroup>
                    </Form>
                    : ""
                }
            </CardFooter>
        </Card>
        </>
    )
};

export default OrderCard;